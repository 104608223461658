/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { tcFont } from "../styles/Global";

import { LogoGrad, LogoWhite } from "../img/imageModule";
import { Fragment } from "react";



const Logo = props => {
    const logo = css`
        ${tcFont};
        display: flex;
        align-items: center;
        justify-content: left;
        margin: 5px;
        
        h2{
            ${tcFont};
        }
        
        img{   
            object-fit: contain;
            max-width: 45%;
            max-height: 85px;
            padding: 10px;

        }
        ${{ ...props }}
    `;
    return (
        <div css={logo}>
            <img src={ props.logoColor === "white" ? LogoWhite : LogoGrad } alt="Touchcode" />
            {
                props.coBrandLogo 
                    ? <Fragment>
                       + <img src={props.coBrandLogo} alt="logo" />
                    </Fragment> 
                    : <h2>TOUCHCODE</h2>
            }
        </div>
    );
};

export default Logo;