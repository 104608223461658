import { Component } from "react";
import React from "react";
import Message from "../components/landingComponents/Message";
import Header from "../components/Header";
import Logo from "../components/Logo";


export default class ErrorPage extends Component {
    constructor(props) {
        super(props);

    }
    render() { return (
        <div>
            <Header
                primaryColor={this.props.primaryColor} 
                handlePointer={null}
                logo={ <Logo logoColor={this.props.logoColor} coBrandLogo={this.props.coBrandLogo}/> }
            />
            <Message text={this.props.errorMessage}/>
        </div>

    )}
}