/** @jsx jsx */
import { Fragment, useEffect, useState } from "react";
import { jsx, css } from "@emotion/core";
import { useParams } from "react-router-dom";
import { Col, Row, lightGrey } from "../styles/Global";
import Landing from "../pages/Landing";
import Loading from "./Loading";
import Message from "../components/landingComponents/Message";
import ErrorPage from "../pages/ErrorPage";


const demo = css`
    padding-top: 10vh;
    margin: auto;
    max-width: 85vw;
`;

const demoTop = css`
    margin: auto;
    max-width: 85vw;
`;

const DemoWrapper = () => {
    const [campaignData, setCampaignData] = useState(null);
    const [loadingError, setLoadingError] = useState(null);
    const publicServiceKey = process.env.REACT_APP_PUBLIC_SERVICE_KEY || null;
    const apiUrl = process.env.REACT_APP_API_URL || "https://localhost:8080"
    const apiVersion = process.env.REACT_APP_API_VERISON || "2.0.2"
    const { path } = useParams();
    const lowerCase = path ?  path.toLowerCase() : null

    useEffect(() => {
        const fetchCampaignData = async () => {
            if(lowerCase == null) {
                return;
            }
            let result;
            try {
                result = await fetch(`${apiUrl}/${apiVersion}/demoSite?campaign_tag=${lowerCase}`, {
                    headers: {
                        'x-api-key': publicServiceKey
                    }
                });
            }
            catch(e) {
                setLoadingError("Error contacting server, please try again later");
                return;
            }
            if(result.ok) {
                try {
                    var res = await result.json();
                    setCampaignData(res);
                }
                catch(e) {
                    console.log(e);
                    setLoadingError(`No campaign was found for path [${lowerCase}].`)
                }
            }
            else {
                setLoadingError(`No campaign was found for path [${lowerCase}].`)
            }
        };
        fetchCampaignData();
    }, [true]); // Only run once
    const styleJSON = {
        backgroundColor: campaignData !== null ? campaignData.background : lightGrey,
        position: "absolute",
        height: "100%",
        width: "100%"
    }
  
    return (
        <Fragment>
            {   
                lowerCase !== null
                    ? (campaignData !== null ?
                        (
                            <Landing
                                brand={campaignData.brand}
                                campaignId={campaignData.campaignId || null}
                                coBrandLogo={campaignData.coBrandLogo || null}
                                logoColor={campaignData.logoColor || null}
                                messageInsert={campaignData.messageInsert || null}
                                message={campaignData.message}
                                messageColor={campaignData.messageColor}
                                product={campaignData.product}
                                primaryColor={campaignData.primaryColor || null}   
                                successMessage={campaignData.successMessage || null}
                                successSecondaryMessage={campaignData.successSecondaryMessage || null}
                                successMessageColor={campaignData.successMessageColor || null}
                                successTimeout={campaignData.successTimeout || null}
                                style={styleJSON}
                            >
                                {campaignData.logoUrl
                                    ? <Row>
                                        <img css={demoTop} alt={campaignData.logoUrl} src={campaignData.logoUrl} />
                                    </Row>
                                    : null
                                }
                                {campaignData.url
                                    ? <Row>
                                        <img css={campaignData.logoUrl ? demoTop : demo} alt={campaignData.brand || campaignData.url} src={campaignData.url} />
                                    </Row>
                                    : null
                                }
                            </Landing>
                        ) : (loadingError == null ? <Loading/> : <ErrorPage errorMessage={loadingError}/>))
                    : < Landing />
            }
        </Fragment>
    )
}

export default DemoWrapper