/** @jsx jsx */
import { Component } from "react";
import { jsx, css, keyframes } from "@emotion/core";
import Touchcode from "@touchcode/react-touchcode-sdk";
import Touchloader from "../components/landingComponents/TouchLoader";
import Success from "./Success";
import Header from "../components/Header";
import Logo from "../components/Logo";

import HelpMenu from "../components/landingComponents/HelpMenu";
import Message from "../components/landingComponents/Message";
import { Card, Phone } from "../img/imageModule";


const leftShift = css`
    left: -10px;
`;
const touchContainer = css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const imageFlexContainer = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
`;
const imageContainer = css`
    margin: auto;
    position: relative;
    img {
        max-width: 400px;
    }
`;
const slideUpDown = keyframes`
    to{
        transform: translateY(2vh)
    }
    from {
        transform: translateY(9vh)
    }
    
`;
const imageAnimation = css`
    position: absolute;
    ${leftShift};
    animation: ${slideUpDown} 1s infinite alternate linear;
`;
export default class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayHelpMenu: false,
            displayHeader: true,
            displaySuccess: false,
            result: null
        };
    }

    handleDecode = result => {    
       
        this.setState({
            displaySuccess: true,
            result: result
        });
    };
 
    componentWillUnmount() {
       
        if (this.state.displaySuccess) {
            this.setState({
                displaySuccess: false,
                result: null
            })
        }
    }
 
    handleTimeout = () => {
        if (this.state.displaySuccess) {
            window.location.assign(this.state.result) 
        }
    };
    handleTouchResponse = bool => {
        this.setState({
            displayHeader: bool
        });
    }
    handleHelpMenu = e => {
        this.setState({
            displayHelpMenu: !this.state.displayHelpMenu
        });
    };
    renderHelpMenu = () => {
        return <HelpMenu product={ this.props.product } />;
    };
    render() {
        const { displayHelpMenu, displaySuccess } = this.state;

        return (
            <div>
                {displaySuccess ? (
                    <Success
                        result={this.state.result}
                        message={this.props.successMessage}
                        secondaryMessage={this.props.successSecondaryMessage}
                        messageColor={this.props.successMessageColor}
                        timeout={this.props.successTimeout}
                        onTimeout={this.handleTimeout}
                    />
                ) : (
                    <Touchcode
                        campaignId={this.props.campaignId || "A16E2B7C-901E-4DA2-B2EA-C935828004AC"}
                        element="touchcode_enabled_div"
                        progressCallback={null}
                        decodeCallback={this.handleDecode}
                        autoRedirect={false}
                        style={this.props.style}
                    >

                        {this.state.displayHeader
                            ? <Header
                                primaryColor={this.props.primaryColor} 
                                showX={this.state.displayHelpMenu}
                                handlePointer={this.handleHelpMenu}  
                                logo={ <Logo logoColor={this.props.logoColor} coBrandLogo={this.props.coBrandLogo}/> }
                            />
                            : ""
                        }
                        <Touchloader />
                        {displayHelpMenu ? (
                            this.renderHelpMenu()
                        ) : (
                            <div css={touchContainer}>
                                <Message
                                    product = {this.props.product}
                                    messageColor={this.props.messageColor}
                                    messageInsert = {this.props.messageInsert}
                                    text = {this.props.message}
                                    brand = {this.props.brand}
                                />
                                <div css={imageFlexContainer}>
                                    {
                                        this.props.children && this.props.children.some(child => child != null)
                                            ? this.props.children
                                            : <div css={imageContainer}>
                                                <img alt="touchcode enabled product" css={imageAnimation} src={Card} />
                                                <img alt="phone screen" src={Phone} />
                                            </div>
                                    }
                                </div>
                            </div>
                        )}
                    </Touchcode>
                )}
            </div>
        );
    }
}
