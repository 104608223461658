/** @jsx jsx */
import { useEffect } from "react";
import { jsx, css } from "@emotion/core";
import { limeGreen } from "../styles/Global";

const Success = ({onTimeout, timeout, message, messageColor, secondaryMessage} ) => {

    const success = css`
    display: flex;
    flex-direction: column;
    background-color:${messageColor ||  limeGreen};
    z-index: 100000;
    height:100vh;
    width:100vw;

    h1, h2{
        text-align:center;
        width: auto;
        height: auto;
        color:white;
        padding: 10vh 5vh 0vh 5vh;
    }
 
`;
    
    useEffect(() => {
        const displayTime = setTimeout(() => onTimeout(), (timeout || 750));
        return () => clearTimeout(displayTime);
    }, [timeout, onTimeout])
    
    return (
        <div css={success}>
            <h1 >
                {message || "SUCCESS"}
            </h1>
            <h2 >
                {secondaryMessage || null}
            </h2>
        </div>
    );
}
export default Success