/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { darkBlue, tcFont } from "../styles/Global"



const helpMenu = css`
    position: relative;
    color: white;
    margin: 1vw 5vw 1vw 5vw;
    font-weight: medium;
`;
const Header = props => {
    const navbar = css`
        background-color: ${props.primaryColor || darkBlue};
        z-index: 100;
        width: 100vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: white;
        span{
            ${tcFont}
        }
    `;

    return (
        <nav css={navbar}>
            {props.logo}
            {props.handlePointer !== null
                ? <h3 css={helpMenu} onPointerUp={() => props.handlePointer()} > {props.showX ? "X" : "?"} </h3>
                : ""
            }
            
        </nav>
    );
};

export default Header;
