import React, { lazy , Suspense} from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { GlobalStyles as Global } from "./styles/Global.jsx"
import DemoWrapper from "./components/DemoWrapper";
import Success from "./pages/Success"
const Target = lazy(() => import("./pages/Target"));

const Routes = () => {
    
    return (
        <Router>
            <Global />
            <Suspense fallback={<Success message=""/>}>
                <Switch>
                    <Route exact path="/" component={DemoWrapper} />
                    <Route exact path="/target" component={Target} />
                    <Route exact path="/demo" component={DemoWrapper} />
                    <Route exact path="/demo/:target" component={Target} />
                    <Route exact path="/:path" component={DemoWrapper} />
                </Switch>
            </Suspense>
        </Router>
    );
}

export default Routes

