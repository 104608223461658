/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { lightBlue } from "../../styles/Global";



const Message = ({ messageColor, product = "card", text = null, messageInsert = null, brand = "Touchcode"}) => {
    const  messageStyle = css`
        color: ${ messageColor || lightBlue};
        margin: auto;
        margin-top: 20px;
        text-align: center;
        font-size: 1.2em;
        padding: 10px;
        z-index: 100;
    `;

    return (
        <p css={messageStyle}>
            { text
                ? text
                : `Touch ${ product } firmly against screen ${ messageInsert || `to unlock your authentic ${brand} experience`}` 
            }
            
        </p>
    )
}

export default Message