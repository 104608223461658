/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { lightBlue } from "../../styles/Global"

const helpMenu = css`
    font-size: 1.4em;
    height: 100%;
    margin: auto;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    ul {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 60%;
        margin: auto;
        list-style-type: none;
    }
    color: ${lightBlue};
`;

const HelpMenu = ({product = "card"}) => {
    return (
        <div css={helpMenu}>
            <ul>
                <li>1. Make sure your hands aren’t on your screen</li>
                <li>
                    {`2. Make sure your fingers are on the “hold here” section on
                    the ${ product }`}
                </li>
                <li>{`3. The ${ product} should be held firmly against the device`}</li>
                <li>{`4. Do not crease or fold the ${ product }`}</li>
                <li>{`5. Move ${ product } as shown`}</li>
            </ul>
        </div>
    );
};

export default HelpMenu;
