/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";
import { useState, useEffect, useRef } from "react";

const limeGreen = "#78be20";
const darkBlue = "#1f2a44";

const container = props => css`
        position: absolute;
        ${props.displayLoader === true ? "top:0; left:0;" : ""}
        width: 100vw;
        height: 100vh;
        background-color: transparent;
        z-index: 1000;
        display: flex;
        flex-direction: column;
    `;
const Container = styled.div`
    ${container};
`;
const loadingBar = css`
    width:80%;
    height 40px;
    border:1px solid white;
    border-radius:20px;
    display: flex;
    justify-content: start;
    padding 1px;
    overflow:hidden;
    margin-top:5%;
`;
const text = css`
        margin-top:10vh;
    `;
const loadingBarContainer = css`
        height: 100%;
        width: 100%;
        color: white;
        margin: auto;
        z-index:0;
        background-color: ${darkBlue};
        display: flex;
        flex-direction: column;
        div {
            margin-left: auto;
            margin-right: auto;
        }
    `;


const loadingBarProgress = props => css`
        background-color: ${limeGreen};
        width: ${props.touches * 20}%;
     
        height: 100%;
        margin: 0 !important;
        border-radius: 20px;
    `;

const Progress = styled.div`
    ${loadingBarProgress};
`;
    
const Touchloader = (props) => {
    const timer = useRef(null);
    const [touches, setTouches] = useState(0);
    const [displayLoader, setDisplayLoader] = useState(false);
    const [lastTouchTime, setLastTouchTime] = useState(Date.now());

    useEffect(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            setDisplayLoader(false);
            setTouches(0);
        }, 1500);
        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, [touches, lastTouchTime]);

    const handleTouches = e => {
        e.persist();
        if (e.type === "touchstart") {
            if (!displayLoader) {
                setDisplayLoader(true);
            }
        }
        if (e.type === "touchend" || e.type === "touchcancel") {

            if (e.touches.length < 1) {
                setDisplayLoader(false);
            }
        }
        setTouches(e.touches.length);
        setLastTouchTime(Date.now());
    };
    const Loader = ({ progress }) => {
        return (
            <div css={loadingBarContainer}>
                <div css={text}>
                    <h3>Unlocking your experience!</h3>
                    <h3>Keep holding...</h3>
                </div>
                <div css={loadingBar}>
                    {progress}
                </div>
            </div>
        )
    };
    return (
        <Container displayLoader={displayLoader} onTouchStart={handleTouches} onTouchMove={handleTouches} onTouchEnd={handleTouches}>
            {displayLoader
                ? <Loader progress={
                    <Progress onTouchStart={handleTouches} onTouchMove={handleTouches} onTouchEnd={handleTouches} touches={touches} />
                } />
                : null
            }
        </Container>
    );
};

export default Touchloader;
