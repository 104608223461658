import React from "react";
import { Fragment } from "react";
import ClipLoader from "react-spinners/ClipLoader";
const Loading = () => {
    const styleJson = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)"
    }
    return (<div style={styleJson}><ClipLoader loading={true} size={150}/></div>)
}
export default Loading;