/** @jsx jsx */
import { jsx, css, Global } from "@emotion/core";
import styled from "@emotion/styled";

const darkBlue = "#1f2a44";
const limeGreen = "#78be20";
const white = "#FFFFFF";
const lightBlue = "#009cde";
const lightGrey = "#E6E6E6";
const tcFont = css`
    font-family: brother-1816, sans-serif;
    font-weight: normal;
    `;
const tcFontBold = css`
    ${tcFont}
    font-weight:bold;
    `;
const textFont = css`
     font-family: open-sans, sans-serif;
     font-weight:bold;
`;

const flex = props => css`
    display:flex;
    justify-content: ${(props.between && "space-between") || (props.start && "flex-start") || (props.end && "flex-end") || (props.evenly && "space-evenly") || (props.around && "space-around") || "center"};
    align-items:${props.align || "center"};
`;
const Row = styled.div(props => (css`
    ${flex(props)}
`))
const Col = styled.div(props => (css`
    ${flex(props)}
    flex-direction:column;
`))

const gradiant = props => css`
    height:${props.height || "100%"};
    opacity:${props.opacity || 1};
    width:100%;
    background: linear-gradient(to ${props.orientation || "left"}, ${lightBlue}, ${limeGreen} 75%);
`;
const Gradiant = styled.div`${gradiant}`;

const resets = css`
    *, *::after, *::before{
        ${textFont}
        margin:0;
        padding: 0;
        border: 0;
        vertical-align: baseline;

        box-sizing: border-box;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
    }
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video, button{
        margin: 0;
        padding: 0;
        border: 0;
        vertical-align: baseline;
    }
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
        display:block;
    }
    body {
        background-color:${lightGrey}
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    a{
        text-decoration: none;
        &:active, &:visited, &:focused{
            text-decoration: none;
        }
    }
`;
const size = 8;
const maxSize = 48;
const decr = (_size, _multi) => _size * _multi;
const GlobalStyles = props => {
    return (
        <Global styles={css`
        @import url("https://use.typekit.net/ulp2eot.css");
           ${resets}
            html{
              font-size: min(${decr(size, .45)}vw, ${decr(maxSize, .45)}px);
            }
            h1{
               font-size: min(${size}vw, ${maxSize}px);
            }
            h2{
                font-size: min(${decr(size, .85)}vw, ${decr(maxSize, .85)}px);
            }
            h3{
                font-size: min(${decr(size, .7)}vw, ${decr(maxSize, .7)}px);
            }
            h4{
                font-size: min(${decr(size, .55)}vw, ${decr(maxSize, .55)}px);
            }
            ${props}
        `} />
    )
}

export { GlobalStyles, darkBlue, limeGreen, lightGrey, lightBlue, white, tcFont, tcFontBold, Gradiant, Col, Row }